import React from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  LinearProgress,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper
} from '@mui/material';
import {
  AttachMoney,
  Person,
  ShoppingCart,
  Message,
  Facebook,
  Google,
  LinkedIn,
  Twitter
} from '@mui/icons-material';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import Navigation from '../components/Navigation';


const Dashboard = () => {
  // Your existing data
  const metrics = [
    { title: "Weekly sales", value: "714k", change: "+2.5%", icon: AttachMoney, color: "#bbdefb" },
    { title: "New users", value: "1.35m", change: "-0.1%", icon: Person, color: "#e1bee7" },
    { title: "Purchase orders", value: "1.72m", change: "+2.8%", icon: ShoppingCart, color: "#fff3e0" },
    { title: "Messages", value: "234", change: "+3.6%", icon: Message, color: "#ffcdd2" }
  ];

  const websiteVisits = [
    { month: 'Jan', teamA: 40, teamB: 55 },
    { month: 'Feb', teamA: 35, teamB: 45 },
    { month: 'Mar', teamA: 30, teamB: 20 },
    { month: 'Apr', teamA: 35, teamB: 45 },
    { month: 'May', teamA: 55, teamB: 50 },
    { month: 'Jun', teamA: 50, teamB: 55 }
  ];

  const regionData = [
    { name: 'America', value: 43.8, color: '#1976d2' },
    { name: 'Asia', value: 31.2, color: '#ff9800' },
    { name: 'Europe', value: 18.5, color: '#673ab7' },
    { name: 'Africa', value: 6.5, color: '#f44336' }
  ];

  const conversionData = [
    { name: 'Italy', value: 65 },
    { name: 'Japan', value: 59 },
    { name: 'China', value: 45 },
    { name: 'Canada', value: 55 },
    { name: 'France', value: 40 }
  ];

  return (
    <Navigation>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Metrics */}
          {metrics.map((metric, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ bgcolor: metric.color, height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography color="text.secondary" variant="subtitle2">
                        {metric.title}
                      </Typography>
                      <Typography variant="h4" sx={{ my: 1 }}>
                        {metric.value}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={metric.change.startsWith('+') ? 'success.main' : 'error.main'}
                      >
                        {metric.change}
                      </Typography>
                    </Box>
                    <metric.icon sx={{ fontSize: 40, opacity: 0.7 }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Charts */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Website Visits" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart data={websiteVisits}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Bar dataKey="teamA" fill="#1976d2" />
                      <Bar dataKey="teamB" fill="#90caf9" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Current Visits" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={regionData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        dataKey="value"
                      >
                        {regionData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Conversion Rates */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Conversion Rates" />
              <CardContent>
                {conversionData.map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">{item.name}</Typography>
                      <Typography variant="body2">{item.value}%</Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={item.value} 
                      sx={{ height: 8, borderRadius: 1 }}
                    />
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Navigation>
  );
};

export default Dashboard;