import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from './pages/dashboad';


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<div>Users Page</div>} />
          <Route path="/products" element={<div>Products Page</div>} />
          <Route path="/blog" element={<div>Blog Page</div>} />
          <Route path="/messages" element={<div>Messages Page</div>} />
          <Route path="/settings" element={<div>Settings Page</div>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;